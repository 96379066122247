<template>
  <div class="d-flex justify-space-between align-center mt-4">
    <div>
      <span class="total__product__text">สินค้าทั้งหมด</span>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="hidden-md-and-down"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="dialog__filter__sort__btn mx-2 text-capitalize hidden-lg-and-up"
            depressed
            rounded
            v-bind="attrs"
            v-on="on"
          >
            หมวดหมู่
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>หมวดหมู่</v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <div class="d-flex justify-space-between align-center mb-4">
              <span class="sort__text mr-4">จัดเรียงโดย</span>
              <v-menu
                key="Large"
                rounded="lg"
                offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    class="sort__menu__button d-flex justify-center align-center"
                    v-bind="attrs"
                    small
                    depressed
                    v-on="on"
                  >
                    {{ sort.label }}
                    <v-icon
                      dark
                      right
                      medium
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="item in items"
                    :key="item.label"
                    link
                    @click="onSortClick(item)"
                  >
                    <v-list-item-title
                      class="sort__menu__text"
                      v-text="item.label"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <ShopFilter
              :category-list="categoryList"
              :category-filter="categoryFilter"
              @setCategoryFilter="setCategoryFilter"
            />
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <div class="hidden-md-and-down">
      <div class="d-flex justify-center align-center">
        <span class="sort__text mr-4">จัดเรียงโดย</span>
        <v-menu
          key="Large"
          rounded="lg"
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="sort__menu__button d-flex justify-center align-center"
              v-bind="attrs"
              small
              depressed
              v-on="on"
            >
              {{ sort.label }}
              <v-icon
                dark
                right
                medium
              >
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item.label"
              link
              @click="onSortClick(item)"
            >
              <v-list-item-title
                class="sort__menu__text"
                v-text="item.label"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import ShopFilter from './ShopFilter.vue';

export default {
  name: 'ShopSort',
  components: {
    ShopFilter,
  },
  props: {
    sort: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    categoryList: {
      type: Array,
      default: () => [],
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    onSortClick(sort) {
      this.$emit('setSort', sort);
    },
    setCategoryFilter(newCategoryFilter) {
      this.$emit('setCategoryFilter', newCategoryFilter);
    }
  }
};
</script>
