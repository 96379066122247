<template>
  <div>
    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      height="100%"
      class="ecommerce__banner"
    >
      <v-carousel-item
        v-for="(image, i) in bannerImages"
        :key="i"
        eager
      >
        <a :href="image.url">
          <v-img
            :src="image.image"
            eager
          />
        </a>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import banner1 from '@/assets/images/banner/banner-1.jpg';
import banner2 from '@/assets/images/banner/banner-2.jpg';
import tradeCapsuleBanner from '@/assets/images/banner/trade-capsule.jpg';

export default {
  name: 'ShopBanner',
  data: () => ({
    bannerImages: [
      {
        image: tradeCapsuleBanner,
        url: "/capsule-market"
      },
      {
        image: banner1,
        url: "/capsule-market"
      },
      {
        image: banner2,
        url: "/referral"
      }
    ]
  })
};
</script>
