<template>
  <div>
    <span class="total__product__text">สินค้าแนะนำ</span>
    <v-carousel
      hide-delimiters
      class="recommendation-container mt-2"
      show-arrows
      :height="maxHeight"
    >
      <v-carousel-item
        v-for="(productArray, index) in products"
        :key="index"
      >
        <v-row class="ecommerce__product__container my-0">
          <v-col
            v-for="product in productArray"
            :key="product.id"
            cols="6"
            sm="6"
            md="3"
          >
            <ProductCard
              :product="product"
            />
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'Recommendation',
  components: {
    ProductCard,
  },
  props: {
    products: {
      type: Array,
      default: () => {},
    }
  },
  data: () => ({
    maxHeight: window.innerWidth < 960 ? 350 : 450
  }),
};
</script>
<style>
.recommendation-container {
  width: 100%;
  position: relative;
}

.recommendation-product-card {
  display: inline-flex;
  flex-basis: 33%;
  flex: 1;
}
</style>