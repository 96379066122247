<template>
  <div>
    <v-row v-if="!loading" class="ecommerce__product__container my-0">
      <v-col
        v-for="product in products"
        :key="product.id"
        cols="6"
        sm="6"
        md="3"
      >
        <ProductCard
          :product="product"
        />
      </v-col>
      <div v-if="!loading && products.length === 0" class="d-flex flex-column align-center full-width my-16">
        <span class="search__not__found__text">ไม่พบสินค้าที่คุณค้นหา</span>
        <span class="search__again__text">กรุณาค้นหาสินค้าใหม่อีกครั้ง</span>
      </div>
    </v-row>
    <div
      v-if="loading"
      class="full-width d-flex justify-center align-center my-16 py-16"
    >
      <v-progress-circular
        color="secondary"
        indeterminate
        :size="80"
        :width="10"
      />
    </div>
    <div :class="loading ? 'd-none' : 'd-flex justify-end'">
      <Pagination
        v-if="products.length > 0"
        :items="products"
        :total-items="total"
        :page="page"
        :page-size="pageSize"
        :styles="customStyles"
        :labels="customLabels"
        @changePage="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  GET_PRODUCTS,
} from '@/store/_actionTypes';

import ProductCard from '@/components/ProductCard.vue';
import Pagination from '@/components/Pagination.vue';

const customStyles = {
  li: {
    display: 'inline-block',
  },
  a: {
    color: '#242424',
  },
};
const customLabels = {
  first: 'First',
  last: 'Last',
  previous: '<',
  next: '>',
};

export default {
  name: 'ShopProduct',
  components: {
    ProductCard,
    Pagination
  },
  data: () => ({
    pageSize: 12,
    customStyles,
    customLabels,
  }),
  computed: {
    ...mapState('shop', [ 'categories', 'categoryFilter', 'sort', 'minPrice', 'products', 'maxPrice', 'page', 'keyword', 'total', 'loading' ]),
    ...mapState('user', [ 'accessToken' ]),
  },
  methods: {
    ...mapActions('shop', [ GET_PRODUCTS ]),
    onChangePage(page) {
      window.scrollTo(0, 0);
      this[GET_PRODUCTS]({
        keyword: this.keyword,
        sort: this.sort.id,
        categoryFilter: this.categoryFilter,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        page: page,
        accessToken: this.accessToken,
      });
    },
  },
};
</script>
