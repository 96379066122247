<template>
  <v-container class="mb-16">
    <v-row no-gutters>
      <v-col
        cols="2"
        class="hidden-md-and-down pr-6"
      >
        <ShopFilter
          :category-list="getCategoriesWithPrice()"
          :category-filter="categoryFilter"
          @setCategoryFilter="setCategoryFilter"
        />
      </v-col>
      <v-col
        cols="12"
        lg="10"
        class="mb-16"
      >
        <ShopBanner />
        <Recommendation
          v-if="recommendation.length > 0"
          :products="recommendation"
          class="mt-2"
        />
        <div v-if="!isLogin">
          <img
            style="width:100%;cursor:pointer;"
            src="@/assets/images/banner/banner-B-100.jpeg"
            @click="showLoginDialog"
          >
        </div>
        <ShopSort
          :sort="sort"
          :items="sortItems"
          :category-list="getCategoriesWithPrice()"
          :category-filter="categoryFilter"
          @setCategoryFilter="setCategoryFilter"
          @setSort="setSort"
        />
        <ShopProduct
          :products="products"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  GET_CATEGORIES,
  GET_PRODUCTS,
  GET_RECOMMENDATION,
  UPDATE_CATEGORY_FILTER,
  UPDATE_SORT,
  UPDATE_MIN_PRICE,
  UPDATE_MAX_PRICE,
  UPDATE_PAGE,
  CONFIRM_USER,
  SHOW_LOGIN_DIALOG
} from '@/store/_actionTypes';

import ShopBanner from './components/ShopBanner.vue';
import ShopSort from './components/ShopSort.vue';
import ShopProduct from './components/ShopProduct.vue';
import ShopFilter from './components/ShopFilter.vue';
import Recommendation from './components/Recommendation.vue';

export default {
  name: 'Shop',
  components: {
    ShopBanner,
    ShopSort,
    ShopProduct,
    ShopFilter,
    Recommendation
  },
  data: () => ({
    sortItems: [
      { id: 'popular', label: 'สินค้ายอดนิยม' },
      { id: 'latest', label: 'สินค้ามาใหม่' },
      { id: 'price_asc', label: 'สินค้าโปรโมชั่น' },
      { id: 'price_asc', label: 'ราคาน้อย-มาก' },
      { id: 'price_desc', label: 'ราคามาก-น้อย' },
    ],
    priceFilter: [
      {
        id: 'price-1',
        name: 'Price',
        children: [
          {
            id: 'price-20-100',
            name: '20 - 100',
          },
          {
            id: 'price-100-500',
            name: '100 - 500',
          },
          {
            id: 'price-500-1000',
            name: '500 - 1,000',
          },
          {
            id: 'price-1000-2000',
            name: '1,000 - 2,000',
          },
          {
            id: 'price-2000-5000',
            name: '2,000 - 5,000',
          }
        ],
      },
    ],
  }),
  created() {
    this[GET_RECOMMENDATION]({
      accessToken: this.accessToken
    });

    this[GET_PRODUCTS]({
      keyword: this.keyword,
      sort: this.sort.id,
      categoryFilter: this.categoryFilter,
      accessToken: this.accessToken,
    });

    this[GET_CATEGORIES]();

    const token = this.$route.query.token;
    if (!!token) this[CONFIRM_USER](token);

    if (!this.accessToken && window.innerWidth < 960) {
      this.showLoginDialog();
    }
  },
  computed: {
    ...mapState('shop', [ 'categories', 'categoryFilter', 'sort', 'products', 'minPrice', 'maxPrice', 'page', 'keyword', 'total', 'recommendation' ]),
    ...mapState('user', [ 'accessToken' ]),
    ...mapGetters('shop', [ 'getCategories' ]),
    isLogin() {
      if (!!this.accessToken) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('shop', [ GET_CATEGORIES, GET_PRODUCTS, UPDATE_CATEGORY_FILTER, UPDATE_SORT, UPDATE_MIN_PRICE, UPDATE_MAX_PRICE, UPDATE_PAGE, GET_RECOMMENDATION ]),
    ...mapActions('user', [ CONFIRM_USER ]),
    ...mapActions('app', [SHOW_LOGIN_DIALOG]),
    getCategoriesWithPrice() {
      return [...this.getCategories, ...this.priceFilter];
    },
    setCategoryFilter(newCategoryFilter) {
      let sameValues = this.categoryFilter.every(a => newCategoryFilter.some(b => a === b));
      let sameLength = this.categoryFilter.length === newCategoryFilter.length;
      let arrayEqual = sameValues && sameLength;
      if(arrayEqual) return;

      let priceFilter = newCategoryFilter
        .filter(item => `${item}`.includes("price-"))
        .map(item => `${item}`.replace("price-", ""));
      const priceRange = this.setPrice(priceFilter);

      this[UPDATE_PAGE](1);
      this[UPDATE_CATEGORY_FILTER](newCategoryFilter);
      this[GET_PRODUCTS]({
        keyword: this.keyword,
        sort: this.sort.id,
        categoryFilter: newCategoryFilter,
        minPrice: priceRange[0],
        maxPrice: priceRange[1],
        page: this.page,
        accessToken: this.accessToken,
      });
    },
    setSort(newSort) {
      this[UPDATE_PAGE](1);
      this[UPDATE_SORT](newSort);
      this[GET_PRODUCTS]({
        keyword: this.keyword,
        sort: newSort.id,
        categoryFilter: this.categoryFilter,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        page: this.page,
        accessToken: this.accessToken,
      });
    },
    setPrice(priceFilter) {
      if (priceFilter.length === 0) {
        this[UPDATE_MIN_PRICE](undefined);
        this[UPDATE_MAX_PRICE](undefined);
        return [];
      }

      let prices = [];
      priceFilter.forEach(priceRange => {
        priceRange
          .split('-')
          .forEach(price => prices.push(price));
      });
      prices.sort((a, b) => a - b);
      this[UPDATE_MIN_PRICE](prices[0]);
      this[UPDATE_MAX_PRICE](prices[prices.length - 1]);
      return [prices[0], prices[prices.length - 1]];
    },
    showLoginDialog() {
      this[SHOW_LOGIN_DIALOG](true);
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/shop/shop.scss';
@import '@/styles/shop/pagination.scss';
</style>
