<template>
  <div>
    <div class="d-flex align-center">
      <v-icon>mdi-format-list-bulleted</v-icon>
      <span class="category__header ml-3">Categories</span>
    </div>
    <div class="mt-3">
      <v-treeview
        v-model="categoryFilterValue"
        dense
        open-all
        selectable
        selected-color="secondary"
        open-on-click
        :items="categoryList"
      >
        <template slot="label" slot-scope="props">
          <div 
            class="category__label ml-1 my-1"
            @click="onLabelClick(props)"
          >
            {{ props.item.name }}
          </div>
        </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopFilter',
  props: {
    categoryList: {
      type: Array,
      default: () => [],
    },
    categoryFilter: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    categoryFilterValue: {
      get() {
        return this.categoryFilter;
      },
      set(newCategoryFilter) {
        this.$emit('setCategoryFilter', newCategoryFilter);
      }
    }
  },
  methods: {
    onLabelClick(props) {
      const isLeaf = props.leaf;
      const categoryId = props.item.id;
      const selected = props.selected;
      const childredIds = props.item.children ? props.item.children.map(root => root.id) : [];
      if (childredIds.length > 0) return;

      let newCategoryFilter = [];

      if (selected) {
        const filterParentAndChildOut = category => !childredIds.includes(category) && categoryId != category;
        newCategoryFilter = this.categoryFilter.filter(filterParentAndChildOut);
      } else if (isLeaf || childredIds.length === 0) {
        newCategoryFilter = [categoryId, ...this.categoryFilter];
      } else {
        newCategoryFilter = [...childredIds, ...this.categoryFilter];
      }
      
      this.$emit('setCategoryFilter', newCategoryFilter);
    }
  }
};
</script>
